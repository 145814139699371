import "./App.css";
import "./global/primary.css";
import "./global/grid.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorComponent from "./components/Error Component/ErrorComponent";
import BookDemo from "./pages/BookDemo";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/agentforce" element={<BookDemo />} />
        <Route path="/error" element={<ErrorComponent />} />
        <Route path="*" element={<ErrorComponent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
