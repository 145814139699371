import React from "react";
import "./Sidebar.css";
import Steps from "../FormSteps/Steps";
import akiraAILogo from "../../assets/Agent force white logo.svg";
export default function BookDemoSidebar({ step }) {
  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-content">
        <img src={akiraAILogo} alt="akiraAILogo" />
        <h1>Book Demo</h1>
        <p>Book a time to chat 1:1 with a Akira team member. </p>
      </div>
      <Steps step={step} />
    </div>
  );
}
